/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import Loading from '@domui-components/Loading';
import Metadata from '@domui-utils/workflow/metadata';

const Timezones: React.FC = () => {
  const [timezonesData, setTimezonesData] = useState<any>({});

  useEffect(() => {
    async function fetchTimezonesData() {
      const wfService = new Metadata();
      const response = await wfService.getTimezones();
      setTimezonesData(response);
    }
    fetchTimezonesData();
  }, []);

  if (!timezonesData) {
    return <Loading />;
  }

  return (
    <div>
      {!!timezonesData?.length && (
        <div style={{ alignItems: 'center' }} className="max-w-4xl mb-8">
          <h3>Timezones Page</h3>
          <ul>
            {timezonesData.map((item: any) => (
              <div key={item.id}>
                <li className="mb-0 last:mb-4">
                  {`Id: ${item.id}`} - {`Value: ${item.value}`} -{' '}
                  {`TimeZoneName: ${item.timeZoneName}`} -{' '}
                  {`Location: ${item.location}`} - {`Offset: ${item.offset}`}
                </li>
              </div>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Timezones;
